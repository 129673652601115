module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"40ftSlinky-portfolio","short_name":"portfolio","start_url":"/","background_color":"#ededed","theme_color":"#50e3c2","display":"minimal-ui","icon":"src/images/40ftSlinky_icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3f516c7fb60450c1cad6085fb6cb8cf9"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
